import { Button, Stack, Text, Title } from "@mantine/core";
import Head from "next/head";
import Link from "next/link";
import React from "react";
import Layout from "src/layout/Layout";

const NotFound = () => {
  return (
    <Layout>
      <Head>
        <title>404 | Nerval ML</title>
        <meta name="robots" content="noindex,nofollow" />
      </Head>
      <Stack mt={100} justify="center" align="center">
        <Title fz={150} style={{ fontFamily: "monospace" }}>
          404
        </Title>
        <Title order={2}>Nothing to see here</Title>
        <Text c="dimmed" maw={800} style={{ textAlign: "center" }}>
          The superintelligence you&apos;re looking for isn&apos;t here. You may have mistyped the
          address, or the page has been moved to another URL. If you think this is an error, contact
          support.
        </Text>
        <Link href="/">
          <Button size="lg" color="gray" type="button">
            Go Home
          </Button>
        </Link>
      </Stack>
    </Layout>
  );
};

export default NotFound;
